<template>
  <header :class="$style.wrapper">
    <Mobile :links="links" :class="$style.mobile" />

    <Desktop :links="links" :class="$style.desktop" />
  </header>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import Desktop from './components/Desktop'
import Mobile from './components/Mobile'

export default defineComponent({
  components: { Desktop, Mobile },
  setup() {
    const links = [
      {
        label: 'comment ca marche ?',
        src: 'https://www.japhy.fr/sur-mesure/comment-ca-marche',
      },
      {
        label: 'Avis client',
        src: 'https://www.japhy.fr/sur-mesure/avis',
      },
      {
        label: 'Nos produits',
        links: [
          {
            label: 'Pour chiens',
            src: 'https://japhy.fr/sur-mesure/collection-chien',
          },
          {
            label: 'Pour chats',
            src: 'https://japhy.fr/sur-mesure/collection-chat',
          },
        ],
      },
    ]

    return { links }
  },
})
</script>

<style lang="scss" module>
.wrapper {
  z-index: $ds-z-index-banner;
}

.mobile {
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: none;
  }
}

.desktop {
  display: none;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: flex;
  }
}
</style>
